import React, { Fragment, useState } from 'react';
import { SportTabs, Timer } from "App/components";
import { Utils } from "App/utils";
import _ from "App/helper/_";
import { useNavigate } from 'react-router-dom';
import { Images } from 'App/constants';



const FixtureListView = ({ EventDetails, location, selectedSportTab, currentUser, pinUnpin }) => {
    const [state, setAllStateValue] = useState({})

    const navigate = useNavigate();
    const gotoDetail = (event_id) => {
        if (currentUser.username) {
            navigate(`/match-detail/${event_id}`)
        } else {
            navigate(`/detail/${event_id}`)
        }
    }

    const setState = (obj, callback) => {
        setAllStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    // onClick={() => { pinUnpin(item.event_id) }} 
    return (
        <Fragment>
            {EventDetails &&
                <Fragment>
                    <div className=" mb-2 hidden md:block">

                        {EventDetails && EventDetails.records && EventDetails.records && Object.keys(EventDetails.records).map((Sport, index) => (
                            <Fragment>
                                {EventDetails.records[Sport].length > 0 &&
                                    <div className='mb-2'>
                                        <div className='grid grid-cols-12 text-[14px] font-[500] bg-active text-active-foreground h-[60px] rounded-[8px_8px_0_0] items-center'>
                                            <div className="col-span-5 pl-2 h-full flex items-center sports-bg " >
                                                <p className='flex items-center text-nowrap text-base lg:text-xl'>
                                                    <img className="h-4 mr-2" src={
                                                        Sport == "Football" ? Images.SOCCER.default
                                                            : Sport === "Tennis" ? Images.TENNIS.default
                                                                : Sport === "Election" ? Images.POLITICS.default
                                                                    : Sport === "Cricket" ? Images.CRICKET.default
                                                                        : Sport === "Cup Winner" ? Images.TROPHY.default
                                                                            : ""} alt={Sport} />
                                                    {Sport}
                                                    {/* <div className="pl-2 cursor-pointer" onClick={() => setState({ [Sport]: state[Sport] == false ? true : false })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none" className={"scale-[0.8] " + (state[Sport] == false ? "rotate-180" : "")}>
                                                            <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" className=' stroke-active-foreground' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div> */}
                                                </p>
                                            </div>
                                            <div className='col-span-7 grid grid-cols-3 text-center '>
                                                <div><p className=''>1</p></div>
                                                <div><p className=''>X</p></div>
                                                <div><p className=''>2</p></div>
                                            </div>
                                        </div>
                                        {EventDetails.records[Sport].map((item, idx) => (
                                            <Fragment>
                                                {(state[Sport] == null || state[Sport] == undefined || state[Sport]) &&
                                                    <div className='cursor-pointer grid grid-cols-12 max-h-[77px] items-center py-2 bg-white text-black  border-[0.94px] border-[#FFFFFF1F] dark:border-[#0B0D0E]'>
                                                        <div className="col-span-5 items-center flex gap-2 max-h-[75px]">
                                                            <div className='px-2 z-10'>
                                                                {!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username &&
                                                                    <img src={(item.pin_status) == 1 ? Images.PIN_1.default : Images.PIN.default} alt="" onClick={() => { pinUnpin(item.event_id) }} />}
                                                            </div>
                                                            <div className='font-[500] leading-[16px] w-full'>
                                                                <div className='cursor-pointer' onClick={() => gotoDetail(item.event_id)}>
                                                                    <p className='text-[14px]'>{item.event_name}</p>
                                                                    <p className='text-[12px] text-[#999999]'>{item.league_name}</p>
                                                                </div>
                                                                <div className='mt-1'>
                                                                    <div className='items-center flex justify-between'>
                                                                        <div className='items-center flex'>
                                                                            {item.in_play == 1 ? <p className='w-[30px] h-[14px] p-[1px_4px] bg-[#E9113C] text-[12px] text-[#fff] items-center flex'>Live</p>
                                                                                : Utils.gameDate(item.open_date) === "Live" ? <p className='w-[30px] h-[14px] p-[1px_4px] bg-[#E9113C] text-[12px] text-[#fff] items-center flex'>Live</p>
                                                                                    : <Fragment>
                                                                                        <p className='text-[12px] text-[#717171] dark:text-[#fff] mr-1'>{Utils.gameDate(item.open_date)}</p>
                                                                                        <p className='text-[12px] text-red-500 dark:text-cyan-500'> {Utils.getTime(item.open_date)} </p>
                                                                                    </Fragment>}
                                                                        </div>
                                                                        <div className='items-center flex'>
                                                                            {item.sport_id == 4 && <img src={Images.TV.default} alt="" className='mr-1' />}
                                                                            <img src={Images.CHART.default} alt="" className='mr-1' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-span-7 cursor-pointer" onClick={() => gotoDetail(item.event_id)}>
                                                            {item.runner_odds &&
                                                                !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) ?
                                                                <div className='grid grid-cols-6 text-center'>
                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex) && !_.isNull(JSON.parse(item.runner_odds)[0].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price) ?
                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'></p> */}
                                                                        </div>
                                                                    }
                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex) && !_.isNull(JSON.parse(item.runner_odds)[0].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price) ?
                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }


                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[2]) && !_.isNull(JSON.parse(item.runner_odds)[2]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex) && !_.isNull(JSON.parse(item.runner_odds)[2].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price) ?

                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[2]) && !_.isNull(JSON.parse(item.runner_odds)[2]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex) && !_.isNull(JSON.parse(item.runner_odds)[2].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price) ?
                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }


                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[1]) && !_.isNull(JSON.parse(item.runner_odds)[1]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex) && !_.isNull(JSON.parse(item.runner_odds)[1].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price) ?
                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[1]) && !_.isNull(JSON.parse(item.runner_odds)[1]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex) && !_.isNull(JSON.parse(item.runner_odds)[1].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price) ?

                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                </div> :
                                                                <div className='grid grid-cols-6 text-center'>
                                                                    <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                    <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                    <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                    <div className='w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                    <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                    <div className='w-full max-w-[75px] h-[50px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>}
                                            </Fragment>
                                        ))}
                                    </div>
                                }
                            </Fragment>
                        ))}
                    </div>

                    <div className=" mb-2 md:hidden">
                        {EventDetails && EventDetails.records && EventDetails.records && Object.keys(EventDetails.records).map((Sport, index) => (
                            <Fragment>
                                {EventDetails.records[Sport].length > 0 &&
                                    <div className='mb-2'>
                                        {/* <div className='grid grid-cols-12 text-[18px] font-[500] bg-active text-active-foreground h-[40px] rounded-[8px_8px_0_0] items-center'>
                                            <div className="col-span-5 pl-2" >
                                                <p className='flex items-center text-nowrap'>
                                                    <img className="h-4 mr-2" src={
                                                        Sport == "Football" ? Images.SOCCER.default
                                                            : Sport === "Tennis" ? Images.TENNIS.default
                                                                : Sport === "Election" ? Images.POLITICS.default
                                                                    : Sport === "Cricket" ? Images.CRICKET.default
                                                                        : Sport === "Cup Winner" ? Images.TROPHY.default
                                                                            : ""} alt={Sport} />
                                                    {Sport}
                                                    <div className="pl-2 cursor-pointer" onClick={() => setState({ [Sport]: state[Sport] == false ? true : false })}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none" className={"scale-[0.8] " + (state[Sport] == false ? "rotate-180" : "")}>
                                                            <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" className=' stroke-active-foreground' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                </p>
                                            </div>

                                        </div> */}
                                        {EventDetails.records[Sport].map((item, idx) => (
                                            <Fragment>
                                                {(state[Sport] == null || state[Sport] == undefined || state[Sport]) &&
                                                    <div className='grid grid-cols-12 px-1 mb-1 gap-y-2 max-h-[130px] items-center py-2 bg-secondary text-secondary-foreground  border-[0.94px] border-[#FFFFFF1F] '>
                                                        <div className="col-span-12  flex gap-2 max-h-[75px] justify-between relative">
                                                            <div className='font-[500]  leading-[16px] w-full'>
                                                                <div onClick={() => gotoDetail(item.event_id)} className='cursor-pointer'>
                                                                    <p className='text-[14px]'>{item.event_name}</p>
                                                                    <p className='text-[12px] text-[#999999]'>{item.league_name}</p>
                                                                </div>
                                                                <div className='mt-1'>
                                                                    <div className='items-center flex justify-between'>

                                                                        <div className='items-center flex'>
                                                                            {item.in_play == 1 ? <p className='w-[30px] h-[14px] p-[1px_4px] bg-[#E9113C] text-[12px] text-[#fff] items-center flex'>Live</p>
                                                                                : Utils.gameDate(item.open_date) === "Live" ? <p className='w-[30px] h-[14px] p-[1px_4px] bg-[#E9113C] text-[12px] text-[#fff] items-center flex'>Live</p>
                                                                                    : <Fragment>
                                                                                        <p className='text-[12px]  mr-1'>{Utils.gameDate(item.open_date)}</p>
                                                                                        <p className='text-[12px] text-white dark:text-cyan-500'> {Utils.getTime(item.open_date)} </p>
                                                                                    </Fragment>}
                                                                        </div>

                                                                        <div className='items-center flex absolute right-1 md:relative'>
                                                                            {item.sport_id == 4 && <img src={Images.TV.default} alt="" className='mr-1' />}
                                                                            <img src={Images.CHART.default} alt="" className='mr-1' />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='px-2'>
                                                                {!_.isUndefined(currentUser) && !_.isNull(currentUser) && !_.isEmpty(currentUser) && currentUser.username &&
                                                                    <img src={item.pin_status == 1 ? Images.PIN_1.default : Images.PIN.default} alt="" onClick={() => { pinUnpin(item.event_id) }} />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-span-12 cursor-pointer" onClick={() => gotoDetail(item.event_id)}>
                                                            {item.runner_odds &&
                                                                !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) ?
                                                                <div className='grid grid-cols-6 text-center'>

                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex) && !_.isNull(JSON.parse(item.runner_odds)[0].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price) ?
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'></p> */}
                                                                        </div>
                                                                    }
                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[0]) && !_.isNull(JSON.parse(item.runner_odds)[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex) && !_.isNull(JSON.parse(item.runner_odds)[0].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price) ?
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[0].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }


                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[2]) && !_.isNull(JSON.parse(item.runner_odds)[2]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex) && !_.isNull(JSON.parse(item.runner_odds)[2].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price) ?

                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[2]) && !_.isNull(JSON.parse(item.runner_odds)[2]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex) && !_.isNull(JSON.parse(item.runner_odds)[2].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price) ?
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[2].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }


                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[1]) && !_.isNull(JSON.parse(item.runner_odds)[1]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex) && !_.isNull(JSON.parse(item.runner_odds)[1].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack[0]) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price) ?
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToBack[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                    {item && item.runner_odds && !_.isUndefined(JSON.parse(item.runner_odds)[1]) && !_.isNull(JSON.parse(item.runner_odds)[1]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex) && !_.isNull(JSON.parse(item.runner_odds)[1].ex) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay[0]) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay[0]) &&
                                                                        !_.isUndefined(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price) && !_.isNull(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price) ?

                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].price)}</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>{Utils.TwoDecimalVal(JSON.parse(item.runner_odds)[1].ex.availableToLay[0].size)}</p> */}
                                                                        </div>
                                                                        :
                                                                        <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                            <p className='leading-[16.8px] font-[700]'>--</p>
                                                                            {/* <p className='text-[12px] leading-[12px] font-[500]'>--</p> */}
                                                                        </div>
                                                                    }

                                                                </div> :
                                                                <div className='grid grid-cols-6 text-center'>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-lagai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                    <div className='w-[96%] h-[42.46px] rounded-[1.88px] bg-khai items-center justify-center grid text-[14px]  text-lagai-khai-foreground '>
                                                                        <p className='leading-[16.8px] font-[700]'>--</p>
                                                                        <p className='text-[12px] leading-[12px] font-[500]'>--</p>
                                                                    </div>
                                                                </div>}
                                                        </div>
                                                    </div>}
                                            </Fragment>
                                        ))}
                                    </div>
                                }
                            </Fragment>
                        ))}
                    </div>

                </Fragment>
            }
        </Fragment>
    )
}

export default FixtureListView