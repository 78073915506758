import React, { Fragment, useState } from 'react'
import { loginUser, loginUserByMobile, toggleAuthModalRequest } from "App/_redux/_actions";
import { redirect, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { Images } from "App/constants";
import { otpVerificationRequest } from 'App/services';
import OtpTimer from 'otp-timer'
import { FaWindowClose } from 'react-icons/fa';

const LoginModal = ({ showModal, toggle }) => {
    const dispatch = useDispatch();

    const [state, setState] = useState({
        username: "",
        password: "",
        mobile: "",
        otp: "",
    })
    const [isMobileLogin, setLoginType] = useState(false)
    const [error, setError] = useState(null)
    const [isOtpSended, setOtpSended] = useState(false)
    const [orderId, setOrderId] = useState(null)
    const navigate = useNavigate();


    const requestOtp = async () => {
        setOtpSended(true)
        const param = {
            mobile: state.mobile,
            type: 2
        }
        try {
            dispatch(otpVerificationRequest(param, (response) => {
                if (response.error) {
                    setError(response.error)
                    setOtpSended(false)
                    toast.error(response.error);
                    return
                }

                if (response && response.data && response.data.orderId) {
                    setOrderId(response.data.orderId)
                }

                if (response.data.message != "") {
                    toast.success(response.message);
                    // setTimeout(() => setOtpSended(false), 30000);
                    // clearTimeout()

                }
            }))
        } catch (error) { }
    }

    const submit = async () => {

        if (isMobileLogin) {
            let payload = {
                mobile: state.mobile,
                otp: state.otp,
                orderId
            };
            try {
                // dispatch(mobileLoginRequest(payload, (response) => {
                dispatch(loginUserByMobile(payload, (response) => {
                    if (response.error) {
                        setError(response.error)
                        toast.error(response.error);
                        return
                    }
                    if (response.data.token) {
                        toggleAuthModal(null);
                        navigate('/all-sports');
                    } else {
                        navigate('/login');
                        toast.error("Invalid username or password.");
                    }
                }))

            } catch (error) {
                console.log("error")
            }

        } else {
            let payload = {
                username: state.username,
                password: state.password,

            };
            try {
                dispatch(
                    loginUser(payload, (result) => {
                        if (result.data.token) {
                            toggleAuthModal(null);
                            // navigate('/all-sports');
                            navigate('/login-info');
                        } else {
                            navigate('/login');
                            toast.error("Invalid username or password.");
                        }
                    }))

            } catch (error) {
                console.log("error")
            }
        }


    };


    const handleChange = (e) => {
        const { id, value } = e.target
        setState(prevState => ({
            ...prevState,
            [id]: id == "username" ? value.replace(" ", "") : value
        }))
    }

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }


    return (
        <Fragment>
            {/* old */}
            {false ? (
                <div className="loginModal_otr" >
                    <div class="relative w-full max-w-[503px] max-h-full z-10">
                        {/* Modal content  */}
                        <div class="relative bg-secondary text-secondary-foreground rounded-lg shadow ">
                            {/* Modal header  */}
                            <div class="loginModal_header">
                                <div className='basis-full flex justify-center'>
                                    <h4 className=' text-[24px] font-bold py-2'>Login</h4>
                                    <button onClick={() => toggleAuthModal(null)} type="button" class="anvModal_close regModal-close" data-modal-hide="authentication-modal">
                                        <svg class="w-3 h-3" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLnejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span class="sr-only">Close modal</span>
                                    </button>
                                </div>

                                {/* <div className='basis-full'>
                                    <div>
                                        <img className="max-w-[181px] mx-auto" src={Images.ICON} alt="" />
                                    </div>
                                </div> */}
                            </div>

                            {/* Modal body  */}
                            <div className="px-[28px] pt-4 pb-[20px]">
                                <div role="tablist" className="tabs tabs-boxed anvTab_box">
                                    <div className='backLog_line'></div>

                                    {/* <input type="radio" name="my_tabs_1" role="tab" id='logTab1' className="tab anvLog_tab logTab1" aria-label="Username" checked /> */}

                                    <input type="radio" name="my_tabs_1" role="tab" id='logTab1' className="tab anvLog_tab logTab1" aria-label="Username"
                                        checked={!isMobileLogin} onChange={() => setLoginType(false)} />

                                    {!isMobileLogin && <div role="tabpanel" className="tab-content p-[19px_0px_29px]">
                                        <div className="space-y-4" >
                                            <div className='inputsBx_outer'>
                                                <div className=' absolute left-3 top-[12px]'><img src={Images.USERIMAGE} alt="" /></div>
                                                {/* <label htmlFor="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label> */}
                                                <input
                                                    type="text"
                                                    name="username"
                                                    id="username"
                                                    className="custom_inputTw regCustom_inputTw"
                                                    placeholder="username"
                                                    required
                                                    value={state.username}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='inputsBx_outer'>
                                                <div className=' absolute left-3 top-[12px]'><img src={Images.LOCK} alt="" /></div>
                                                {/* <label htmlFor="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label> */}
                                                <input
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="••••••••"
                                                    className="custom_inputTw regCustom_inputTw"
                                                    required
                                                    value={state.password}
                                                    onChange={handleChange}
                                                />
                                            </div>

                                        </div>
                                    </div>}

                                    {/* <input type="radio" name="my_tabs_1" role="tab" id='logTab2' className="tab anvLog_tab logTab2" aria-label="Mobile" checked={isMobileLogin} onChange={() => setLoginType(true)} /> */}
                                    {isMobileLogin && <div role="tabpanel" className="tab-content p-[19px_0px_29px]">
                                        <div className="space-y-4" >
                                            <div className='inputsBx_outer'>
                                                {/* <label htmlFor="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label> */}
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    id="mobile"
                                                    maxLength={10}
                                                    onInput={maxLengthCheck}
                                                    class="custom_inputTw simCustom_inputTw"
                                                    placeholder="Mobile Number"
                                                    required
                                                    value={state.mobile}
                                                    onChange={handleChange}
                                                />
                                                {error && error.mobile && error.mobile != "" && <small className="text-red-500">{error.mobile}</small>}
                                                <div className=' absolute right-[6px] top-[5px]'>
                                                    {/* <button className={'get_otp text-nowrap ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}
                                                        disabled={state.mobile.length != 10 || isOtpSended}
                                                        onClick={() => requestOtp()}>
                                                        {isOtpSended ? <OtpTimer
                                                            // minutes={0}
                                                            seconds={30}
                                                            textColor={"#fff"}
                                                            text="Resend in :"
                                                            ButtonText="Resend"
                                                            resend={() => requestOtp()}
                                                        /> : "Get OTP"}

                                                    </button> */}
                                                    {isOtpSended ?
                                                        <button className={'get_otp text-nowrap ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}>
                                                            <OtpTimer
                                                                // minutes={0}
                                                                seconds={30}
                                                                textColor={"#fff"}
                                                                text="Resend in :"
                                                                ButtonText="Resend"
                                                                resend={() => requestOtp()}
                                                                background={state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]"}
                                                            />
                                                        </button> :
                                                        <button className={'get_otp text-nowrap ' + (state.mobile.length != 10 ? "bg-[#363636]" : "bg-[#0075d7]")}
                                                            disabled={state.mobile.length != 10 || isOtpSended}
                                                            onClick={() => requestOtp()}>
                                                            Get OTP
                                                        </button>
                                                    }

                                                </div>
                                            </div>

                                            {isOtpSended && <div className='inputsBx_outer'>
                                                {/* <label htmlFor="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your username</label> */}
                                                <input
                                                    type="number"
                                                    name="otp"
                                                    id="otp"
                                                    class="custom_inputTw simCustom_inputTw"
                                                    placeholder="OTP"
                                                    required
                                                    maxLength={6}
                                                    value={state.otp}
                                                    onChange={handleChange}
                                                />
                                                {error && error.otp && error.otp != "" && <small className="text-red-500">{error.otp}</small>}
                                            </div>}
                                        </div>
                                    </div>}

                                </div>

                                <button type="submit"
                                    onClick={() => submit()}
                                    disabled={isMobileLogin ? (state.otp == '' || state.mobile == "") : (state.username == "" || state.password == "")}
                                    className="w-full  anvLogin_btn overflow-hidden relative focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center  focus:ring-[#D8AA32]">
                                    <span className='relative z-10'>Login</span>
                                </button>

                                {/* <div className='mt-[20px] text-center'>
                                    <span className=' text-sm leading-4 font-medium'>Not Registered | <button onClick={() => toggleAuthModal("REGISTER")}> Sign up</button></span>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className='backDrop backdrop-blur-md'></div>
                </div>
            ) : null}

            {/* new  */}
            {true &&
                <Fragment>
                    {/* <div className="loginModal_otr" > */}
                    {/* <div class="relative w-full max-w-[503px] max-h-full z-10">
                    <div class="relative bg-secondary text-secondary-foreground rounded-lg shadow "> */}
                    <div className="fixed loginModalNew inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
                        {/* Modal Container */}
                        <div className="inr w-full mx-2 relative max-w-4xl h-auto rounded-lg shadow-lg flex">
                            {/* Left Image Section */}
                            <div className="hidden login-girl-img lg:block lg:w-1/2 h-full">
                                <img
                                    src="https://assets.xsportsdata.com/asset/asset/girl.webp"
                                    alt="Login Image"
                                    className="object-cover w-full h-full rounded-l-lg"
                                />
                            </div>

                            {/* Right Form Section */}
                            <div className="w-full lg:w-1/2 form-box p-8 flex flex-col justify-center">
                                <div className='grid'>
                                    <img className="" src={Images.ICON}
                                        alt="" />
                                    {/* <h2 className="text-2xl font-bold text-center mb-4 ">LOGIN</h2> */}
                                </div>

                                <div >
                                    {/* Email Field */}
                                    <div className="mb-4 mt-8">
                                        <input
                                            type="text"
                                            id="username"
                                            name="username"
                                            className="custom_inputTw w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 "
                                            placeholder="Enter username"
                                            required
                                            value={state.username}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Password Field */}
                                    <div className="mb-4">
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            className="custom_inputTw w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 "
                                            placeholder="Your password"
                                            required
                                            value={state.password}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    {/* Submit Button */}
                                    <div className="mb-6">
                                        <button
                                            type="submit"
                                            className="w-full py-2 px-4 submt rounded-md focus:outline-none focus:ring-2 "
                                            onClick={() => submit()}
                                            disabled={isMobileLogin ? (state.otp == '' || state.mobile == "") : (state.username == "" || state.password == "")}

                                        >
                                            Login
                                        </button>
                                    </div>


                                </div>

                                {/* Close Button */}
                            </div>
                            <button className="absolute top-2 right-2 text-primary-foreground bg-primary hover:scale-110 " onClick={() => toggleAuthModal(null)} >
                                <FaWindowClose />
                            </button>
                        </div>
                    </div>
                    {/* </div>
                </div> */}
                    <div className='backDrop backdrop-blur-md'></div>
                    {/* </div> */}
                </Fragment>
            }
        </Fragment>
    )
}

export default LoginModal