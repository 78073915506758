import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

const LoginInfo = () => {
    const { currentUser, domainData } = useSelector((state) => state.auth);
    return (
        <div className='fixed top-0 left-0 h-screen w-full z-[100] bg-[#FFF] text-black overflow-auto'>
            <div>
                <div className="container">
                    <div className="bg-[#191919] py-4 text-center text-[#FFF]">
                        <h4 className='text-xl font-bold'> Terms &amp; Conditions  </h4>
                    </div>
                    <br />
                    <ol className="font-semibold leading-6 lg:leading-10 text-sm">
                        <li>

                            1. लोगिन करने के बाद अपना पासवर्ड बदल लें। </li>
                        <li>

                            2. सेशन मैं पैनल्टी के रन Add नही होंगे |</li>
                        <li>

                            3. गेम रद्द होने या टाई होने पर मैच के सभी सौदे रद्द माने जायेंगे और जो सेशन पुरे हो चुके हे, उनके हिसाब से ही Coins कम या ज्यादा होंगे । </li>
                        <li>

                            4. मैच के दौरान भाव को देख व समझ के ही सौदा करे। किये गए किसी भी सौदे को हटाया या बदला नहीं जाएगा। सभी सौदे के लिए स्वयं आप ही जिम्मेदार होंगे।</li>
                        <li>


                            5. मैच या सेशन भाव गलत चलने पर जो भी मैच या सेशन के सौदे हुए हे वह स्वतः हट जायेंगे।</li>
                        <li>


                            6. मैच में जो सेशन कम्पलीट होंगे सिर्फ उनके हिसाब से कॉइन कम या ज्यादा होंगे और जो सेशन कम्पलीट नहीं हुए है बो सारे सेशन रद्द हो जाएंगे|</li>
                        <li>


                            7. मैच मैं ओवर कम होने पर एडवांस सेसन फैंसी कैंसल हो जाएंगी|</li>
                        <li>


                            8. मैच में ओवर कम होने पर अगर सेम टीम दुबारा खेलने के लिए आती है तो जो रनिंग में प्लेयर के रन और पार्टनरशीप डिक्लेयर होगी। अगर ओवर कम होने पर दूसरी टीम खेलने के लिए आती है तो जो रनिंग में प्लेयर रन, पार्टनरशीप रद्द हो जाएंगे </li>
                        <li>


                            9. प्लेयर के रिटायर्ड हर्ट या इंजर्ड होने पर प्लेयर के रन डिक्लेअर किये जायेंगे| </li>
                        <li>


                            10. सेशन को डिक्लेअर और कैंसिल करने के लिए कंपनी का निर्णय अन्तिम होगा| ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं होगा| </li>
                        <li>


                            11. टेस्ट में पारी डिक्लेअर होने पर जो सेशन रनिंग में हे उस सेशन को डिक्लेअर करने के लिए दूसरी टीम के ओवर या बॉल काउंट किये जायेंगे| </li>
                        <li>


                            नोट : सर्वर या वेबसाईट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किये गए सौदे ही मान्य होंगे। ऐसी स्थिति में किसी भी तरह का वाद-विवाद मान्य नहीं होगा।</li>
                    </ol>
                    <br />
                    <Link to="/all-sports">
                        <h4 className="bg-primary text-primary-foreground py-3 text-center lg:mx-16 text-xl font-bold">
                            Continue
                        </h4>
                    </Link>
                </div>
                <br />
                <h4 align="center" style={{ color: '#000', fontFamily: 'Verdana, Geneva, sans-serif', fontSize: 12, fontWeight: 'bold' }}>Copy Right @ {domainData && domainData.website_name && domainData.website_name}</h4>
                <br />
                <br />
                <br />
            </div>

        </div>
    )
}

export default LoginInfo