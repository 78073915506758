const EVENT = {

  "UPDATE_MARKET_ODDS": "updateMarketOdds",
  "UPDATE_FANCY_ODDS": "updateFancyOdds",
  "ADD_UPDATE_FANCY": "addUpdateFancy",
  "UPDATE_SCORE": "updateScore",
  "SETTLEMENT_DONE": "settlement_done",
  "UPDATE_COMMENTRY": "updateCommentary",
  "UPDATE_EVENT_ANNOUNCEMENT": "updateEventAnnouncement",
  "UPDATE_TV_URL": "updateTVURL",
  "UPDATE_LOCK_MARKET_BETTING": "updateLockMarketBetting",
  "UPDATE_USER_LOCK_STATUS": "updateUserLockStatus",
  "UPDATE_FANCY_BET_LOCK": "updateFancyBetLock",
  "UPDATE_FANCY_SECTION_LOCK": "updateFancySectionLock",
  "RELOAD_PAGE": "reloadPage",
  "ALL_ACTIVE_FANCY": "allActiveFancy",
  "MARKET_ANNOUNCEMENT": "marketAnnouncement",
  "PAYING_CALLBACK": "payin_callback",
  "UPDATE_BALANCE": "update_balance",
  "SHOW_HIDE_EVENT": "showHideEvent",
  "SHOW_HIDE_MARKET": "showHideMarket",

}

export default { ...EVENT }
