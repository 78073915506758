import React, { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import closeicon from "../../assets/img/close_icon.png"; //"../../images/close.png";
import deleteBtnPng from "../../assets/img/delete-button.png";
// import { toast, ToastContainer } from "react-toastify";
import { toast } from "react-hot-toast"
import settingBtnPng from "../../assets/img/setting.png";
// import { withRouter } from "react-router-dom";
import { appendStackValue, showMinMax } from "../../utils";
import { FaDeleteLeft } from "react-icons/fa6";

class PlaceMarketBet extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      formObj: {
        amount: 0,
      },
      oneClick: false,
      oneNumClick: false,
    };
  }
  componentDidMount() {
    this.focusInput();
  }

  componentDidUpdate(prevProps) {
    if (this.props.batType != prevProps.batType) {
      this.focusInput();
    }
  }

  focusInput() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  addNumValue = (val) => {
    const { numData } = this.state;
    let value = numData ? parseInt(`${numData}${val}`) : parseInt(val);
    this.setState({ numData: value });
    this.props.setBetAmount(value);

    if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
      this.props.setWinloss((this.props.batType === "KHAI" ? Number(value) :
        this.props.selectedData.market_type == 2 ? ((Number(value) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
          (Number(value) * Math.abs(((+this.props.displayPrice)).toFixed(2)))
      ))
    }
  };

  addBtnValue = (val) => {
    if (val == 0) {

    } else {
      val = Number(this.props.betAmount) + Number(val)
    }
    this.setState({ numData: val });
    this.props.setBetAmount(val);
    // this.props.setBetAmount(Number(this.props.betAmount) + Number(val));

    if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
      this.props.setWinloss((this.props.batType === "KHAI" ? Number(val) :
        this.props.selectedData.market_type == 2 ? ((Number(val) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
          (Number(val) * Math.abs(((+this.props.displayPrice)).toFixed(2)))
      ))
    }

  };

  removeNumValue = () => {
    const { numData } = this.state;
    if (numData) {
      const text = numData.toString();
      if (text.length > 1) {
        const editedText = parseInt(text.slice(0, -1));
        this.setState({ numData: editedText });
        this.props.setBetAmount(editedText);

        if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
          this.props.setWinloss((this.props.batType === "KHAI" ? Number(editedText) :
            this.props.selectedData.market_type == 2 ? ((Number(editedText) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
              (Number(editedText) * Math.abs(((+this.props.displayPrice)).toFixed(2)))
          ))
        }

      } else {
        this.setState({ numData: 0 });
        this.props.setBetAmount(0);

        if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
          this.props.setWinloss((this.props.batType === "KHAI" ? Number(0) :
            this.props.selectedData.market_type == 2 ? ((Number(0) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
              (Number(0) * Math.abs(((+this.props.displayPrice)).toFixed(2)))
          ))
        }
      }
    }
  };

  handleChange = (e) => {
    let { setBetAmount } = this.props;
    setBetAmount(e.target.value);
    if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
      this.props.setWinloss((this.props.batType === "KHAI" ? Number(e.target.value) :
        this.props.selectedData.market_type == 2 ? ((Number(e.target.value) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
          (Number(e.target.value) * Math.abs(((+this.props.displayPrice)).toFixed(2)))
      ))
    }
  };

  onResponseReceived = () => {
    // this.setState({ oneClick : false })
  };

  getOddStackArr = () => {
    const clientLogin = JSON.parse(localStorage.getItem("clientLogin"));
    if (clientLogin && clientLogin.odds_stack) {
      let arr = clientLogin.odds_stack.split(",");
      return arr;
    } else {
      return [];
    }
  };

  checkMinMax = (val) => {
    val = +val
    try {
      let { name, event, selectedData } = this.props;
      if (name !== "" && event !== "" && selectedData !== "") {
        const { min = 0, max = 0 } = showMinMax(name, selectedData, event)
        if (min && max) {
          if (val >= min && val <= max) {
            return true
          } else {
            toast.error(`Bet amount min value is ${min} and max value is ${max}`);
            return false
          }
        } else {
          return true
        }
      }
    } catch (err) {
      toast.error(`Bet Error`);
      return false
    }
  };

  getTime = (addTime = 0) => {
    const { name, batType, selectedData, event } = this.props;
    let delay = 0;
    if (name === 'SESSION') {
      switch (true) {
        case selectedData?.session_type === 1:
          delay = event.session_run_delay;

          break;
        case selectedData?.session_type === 2:
          delay = event.session_run2_delay;

          break;
        case selectedData?.session_type === 3:
          delay = event.fall_of_wicket_delay;

          break;
        case selectedData?.session_type === 4:
          delay = event.player_run_delay;

          break;
        case selectedData?.session_type === 5:
          delay = event.other_fancy_delay;

          break;
        case selectedData?.session_type === 6:
          delay = event.fancy_run_delay;

          break;
        case selectedData?.session_type === 7:
          delay = event.ball_by_ball_delay;
          break;
        case selectedData?.session_type === 8:
          delay = event.advance_fancy_delay;
          break;
      }
    } else {
      switch (selectedData?.market_type) {
        case 1:
          if (+event?.cup_event === 1) {
            delay = event.cup_delay;
          } else {
            delay = event.match_delay;
          }
          break;
        case 2:
          delay = event.bookmaker_delay;
          break;
        default:
          delay = event.other_market_delay;
          break;
      }
    }
    let time = 3000;
    try {
      let { event } = this.props;
      time = delay * 1000
      time += addTime;
      return time;
    } catch (err) {
      return time;
    }
  };

  render() {
    let { setBetAmount, betAmount, placeBetRequest, onDonePressed, selectedData, batType, setWinloss } = this.props;
    return (
      <Fragment>
        {/* <td colSpan={2} className="py-1"> */}
        <div className="bet_tableCover md:block hidden">
          <form data-remote="true"
            id="place-amount-field-div"
            onSubmit={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (!this.state.oneClick && betAmount != 0) {
                if (
                  this.checkMinMax(betAmount) === true
                ) {
                  onDonePressed();
                  this.setState({ oneClick: true });
                  setTimeout(() => {
                    placeBetRequest(this.onResponseReceived);
                  }, this.getTime(0));
                }
              }
            }}>
            <table className="w-full  ">
              <thead>
                <tr className="">
                  <td colSpan={4}>
                    {/* <p className="stak_limit"><span className="pr-1.5">Stake Limit: 100 - 100000 </span> <span> Max Profit: 5000000</span></p> */}

                  </td>
                </tr>
                <tr className="layBack_headTr">
                  <th className="text-[10px] md:text-xs text-[#0BBA3B] text-semibold">{this.props.teamName && this.props.teamName}</th>
                  <th className="text-[10px] md:text-xs text-white text-center font-normal">RETURN</th>
                  <th className="text-[10px] md:text-xs text-white text-center font-normal">
                    {/* {this.props.teamName && this.props.batType} */}
                    {this.props.teamName && this.props.batType == "LAGAI" ? "BACK" : this.props.batType == "KHAI" ? "LAY" : this.props.batType}
                  </th>
                  <th className="text-[10px] md:text-xs text-white text-center font-normal">P-TIME</th>
                </tr>
              </thead>

              <tbody>
                <tr className="layBack_inrheadTr">
                  <td><div className="bet_amtBx">
                    <input
                      ref={this.inputRef}
                      className=""
                      autoComplete="off"
                      onChange={(e) => this.handleChange(e)}
                      value={this.props.betAmount == 0 ? "" : this.props.betAmount}
                      style={{ textDecoration: "none", lineHeight: "normal", background: "none" }}
                      id="coin_countity_even_odd"
                      type="text"
                      autoFocus={true}
                      name="stack"
                      title="Coin"
                      placeholder="Enter Amount"
                    />
                  </div></td>
                  <td className="rtAmt">

                    {(this.props.batType == "KHAI" || this.props.batType == "LAGAI") &&
                      <div>
                        {/* {selectedData.runners.map((run, index) => (
                          <Fragment> */}
                        {/* <div style={{ background: '#fff', display: 'flex', lineHeight: '1', width: '340px' }}> */}
                        {/* <p style={{ width: '50%', margin: '3px' }}>{run.runnerName}</p>
                              <p style={{ width: '25%', margin: '3px' }}>{run.winloss}</p> */}
                        {/* <p style={{ width: '25%', margin: '3px' }}> */}


                        {/* {(this.props.batType === "KHAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                              (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                                <span className={parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"} >
                                  {parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                                </span>
                                :
                                // <span className={parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                                //   {parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                                // </span>
                                null
                            )
                            }
                            {(this.props.batType === "LAGAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                              (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                                <span className={parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "")) : "").toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                                  {parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "")) : "").toFixed(2)}
                                </span>
                                :
                                // <span className={parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                                //   {parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2)}
                                // </span>
                                null
                            )
                            } */}


                        {/* </p> */}
                        {/* </div> */}


                        {/* </Fragment>
                        ))} */}
                        --
                      </div>
                    }
                  </td>
                  <td className="backLagai ">
                    <div className={"text-center rounded-[5px] py-1 " + ((this.props.batType == "KHAI" || this.props.batType == "NO") ? "bg-[#F994BA]" : "bg-[#72BBEF]")}>
                      <p className="mediumPara">
                        {selectedData?.market_type == 1 && batType === "KHAI"
                          // ? Math.abs(((+this.props.displayPrice) - 1).toFixed(2))
                          ? this.props.displayPrice
                          : selectedData?.market_type == 2 && batType === "KHAI"
                            ? Math.abs(((+this.props.displayPrice) - 1) * 100).toFixed(2)
                            // : this.props.displayPrice
                            // : Math.abs(((+this.props.displayPrice) + 1))
                            : Math.abs(((+this.props.displayPrice)))
                        }
                      </p>
                      {/* <p className="xtra_smallPara -mt-1">5.29</p> */}
                    </div>
                  </td>
                  <td>
                    <div className="border-[1.4px] border-[#0BBA3B]">
                      <p className="text-[10px] md:text-[15px] text-[#F60000] font-medium text-center p-[4px_4px] md:p-[6px]">00:09</p>
                    </div>
                  </td>
                </tr>
                <tr className="layBack_inrheadTr">
                  <td colSpan={4}>
                    <div className="flex flex-wrap gap-[6px] sm:gap-[9px]">
                      {this.props.oddsStack.map((stack, ind) =>
                        <p className="betNums_btn" key={stack + ind} amt-value={stack} onClick={() => {
                          // !this.state.oneClick && setBetAmount(stack); if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
                          !this.state.oneClick && this.addBtnValue(stack); if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
                            setWinloss((batType === "KHAI" ? Number(stack) :
                              selectedData.market_type == 2 ? ((Number(stack) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
                                (Number(stack) * Math.abs(((+this.props.displayPrice)).toFixed(2)))))
                          }
                        }}> {appendStackValue(stack)}</p>

                      )}
                      <div className="basis-full justify-end flex flex-wrap gap-[8px]">
                        <button className="custom_btn cancleBet_btn" onClick={() => { !this.state.oneClick && this.props.setToggleBetForm(false); this.props.openMarketBetSlip() }}>Cancel</button>
                        <button className="custom_btn placeBet_btn" type="submit">{this.state.oneClick ? <ClipLoader color={"#666666"} loading={true} size={12} /> : "Place Bet"}</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div className={"place_bet_bottomsheet " + ((this.props.batType == "LAGAI" || this.props.batType == "YES") ? "bg-[#dceaf4]" : "bg-[#f2e5e8]")}>
          {(this.props.batType == "KHAI" || this.props.batType == "LAGAI") && betAmount > 0 &&
            <Fragment>
              {selectedData.runners.map((run, index) => (
                <Fragment>
                  <div className="grid grid-cols-12 border-b border-gray-500 text-[13px]">
                    <div className="col-span-7 text-ellipsis overflow-hidden text-nowrap ">{run.runnerName}</div>
                    <div className="col-span-2 text-right">{Number(run.winloss).toFixed(2)}</div>
                    <div className="col-span-3 text-right">
                      {(this.props.batType === "KHAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                        (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                          <span className={parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"} >
                            {parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                          </span>
                          :
                          <span className={parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                            {parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                          </span>

                      )
                      }
                      {(this.props.batType === "LAGAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                        (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                          <span className={parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "") - 1) : "").toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                            {parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "") - 1) : "").toFixed(2)}
                          </span>
                          :
                          <span className={parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                            {parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2)}
                          </span>

                      )
                      }
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          }


          <form data-remote="true"
            className="mt-2"
            id="place-amount-field-div"
            onSubmit={(e) => {
              e.stopPropagation()
              e.preventDefault()
              if (!this.state.oneNumClick && betAmount != 0) {
                if (
                  this.checkMinMax(betAmount) === true
                ) {
                  onDonePressed();
                  this.setState({ oneNumClick: true });
                  setTimeout(() => {
                    placeBetRequest(this.onResponseReceived);
                  }, this.getTime(0));
                }
              }
            }}>
            <div className="header_bottomsheet">
              <div className="col-span-7">
                <p className="t_name">{this.props.teamName && this.props.teamName}</p>
              </div>
              {/* <div className="col-span-5 text-right t_name">{this.props.batType == "KHAI" ? "Exposure" : "Profit"}</div> */}

              <div className="col-span-7 t_runs">
                {/* {this.props.batType} */}
                {this.props.batType == "LAGAI" ? "BACK" : this.props.batType == "KHAI" ? "LAY" : this.props.batType}
              </div>
              <div className="col-span-5 text-right t_runs">
                {/* {this.props.min_max ? this.props.min_max : null} */}


                {/* {(this.props.batType == "KHAI" || this.props.batType == "LAGAI") &&
                  <div>
                    {selectedData.runners.map((run, index) => (
                      <Fragment> */}

                {/* <div style={{ background: '#fff', display: 'flex', lineHeight: '1', width: '340px' }}> */}
                {/* <p style={{ width: '50%', margin: '3px' }}>{run.runnerName}</p>
                              <p style={{ width: '25%', margin: '3px' }}>{run.winloss}</p> */}
                {/* <p style={{ width: '25%', margin: '3px' }}> */}
                {/* {(this.props.batType === "KHAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                          (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                            <span className={parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"} >
                              {parseFloat(((Number(betAmount)) * - (((this.props.displayPrice) - 1))) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                            </span>
                            :
                            // <span className={parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                            //   {parseFloat(Number(betAmount) + (Number(run.winloss) ? Number(run.winloss) : "")).toFixed(2)}
                            // </span>
                            null
                        )
                        } */}
                {/* {(this.props.batType === "LAGAI" && selectedData.market_id == this.props.marketId && betAmount > 0) && (
                          (this.props.tempWinloss && this.props.tempWinloss != null) && (run.selectionId == this.props.tempWinloss.selectionId) ?
                            <span className={parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "")) : "").toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                              {parseFloat(this.props.tempWinloss.winloss ? (Number(this.props.tempWinloss.winloss) + (Number(run.winloss) ? Number(run.winloss) : "")) : "").toFixed(2)}
                            </span>
                            :
                            // <span className={parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2) > 0 ? "text-green-600" : "text-red-500"}>
                            //   {parseFloat((Number(run.winloss) ? Number(run.winloss) : "") - Number(betAmount)).toFixed(2)}
                            // </span>
                            null
                        )
                        } */}
                {/* </p> */}
                {/* </div> */}

                {/* </Fragment>
                    ))}
                  </div>
                } */}
              </div>

              <div className="col-span-6 ">
                <div className={"text-center rounded-[5px] py-2 bg-white border-1 border-gray-500 text-[14px] " + ((this.props.batType == "LAGAI" || this.props.batType == "NO") ? "" : "")}>
                  <p className="mediumPara py-1">
                    {selectedData?.market_type == 1 && batType === "KHAI"
                      // ? Math.abs(((+this.props.displayPrice) - 1).toFixed(2))
                      ? Math.abs(((+this.props.displayPrice)).toFixed(2))
                      : selectedData?.market_type == 2 && batType === "KHAI"
                        ? Math.abs(((+this.props.displayPrice) - 1) * 100).toFixed(2)
                        // : this.props.displayPrice
                        // : Math.abs(((+this.props.displayPrice) + 1).toFixed(2))
                        : Math.abs(((+this.props.displayPrice)).toFixed(2))
                    }
                  </p>
                </div>
              </div>
              <div className="col-span-6">
                {/* <div className="bet_amtBx_mobile "> */}
                <div className={"text-center rounded-[5px] py-2 bg-white border-1 border-gray-500 text-[14px] "}>
                  <input
                    ref={this.inputRef}
                    className=""
                    autoComplete="off"
                    onChange={(e) => this.handleChange(e)}
                    value={this.props.betAmount == 0 ? "" : this.props.betAmount}
                    style={{ textDecoration: "none", lineHeight: "normal", background: "none", display: 'none' }}
                    id="coin_countity_even_odd"
                    type="text"
                    autoFocus={true}
                    name="stack"
                    title="Coin"
                    placeholder="Enter Amount"
                    readOnly

                  />
                  <p className={"mediumPara py-1 overflow-hidden " + (this.props.betAmount == 0 ? "text-[#afafaf]" : "")}>
                    {this.props.betAmount == 0 ? <span> <span className="blink-input-animation">|</span>Enter Amount</span> : this.props.betAmount}
                  </p>
                </div>
              </div>
              <div className="col-span-12 grid grid-cols-5 gap-1 my-2">
                {this.props.oddsStack.map((stack, ind) =>
                  <p className="betNums_btn_mobile " key={stack + ind} amt-value={stack}
                    onClick={() => {
                      !this.state.oneNumClick && this.addBtnValue(stack); if (this.props.batType == "KHAI" || this.props.batType == "LAGAI") {
                        setWinloss((batType === "KHAI" ? Number(stack) :
                          selectedData.market_type == 2 ? ((Number(stack) * Math.abs(((+this.props.displayPrice)).toFixed(2))) / 100) :
                            (Number(stack) * Math.abs(((+this.props.displayPrice)).toFixed(2)))))
                      }
                    }}> {appendStackValue(stack)}</p>

                )}
              </div>

              <div className="col-span-12 grid grid-cols-3 gap-1">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                  <input
                    key={num}
                    className="betNums_btn_mobile_cal"
                    type="button"
                    value={num}
                    onClick={() => !this.state.oneNumClick && this.addNumValue(num)}
                  />
                ))}

                <p
                  className="bg-gradient-to-t from-red-500 to-red-700 betNums_btn_mobile_cal"
                  onClick={() => !this.state.oneNumClick && this.addBtnValue(0)}
                >
                  Clear
                </p>

                <input
                  className="betNums_btn_mobile_cal"
                  type="button"
                  value="0"
                  onClick={() => !this.state.oneNumClick && this.addNumValue(0)}
                />

                <div
                  className="betNums_btn_mobile_cal bg-white text-[#262626] border-1 border-gray-500 rounded flex justify-center"
                  onClick={() => !this.state.oneNumClick && this.removeNumValue()}
                >
                  <FaDeleteLeft size={18} />
                </div>
              </div>



              <div className="col-span-12">
                <div className="basis-full justify-end flex flex-wrap gap-[8px]">
                  <button className="custom_btn_mobile cancleBet_btn " onClick={() => { !this.state.oneNumClick && this.props.setToggleBetForm(false); this.props.openMarketBetSlip() }}>Cancel</button>
                  <button className="custom_btn_mobile placeBet_btn" type="submit">{this.state.oneNumClick ? <ClipLoader color={"#666666"} loading={true} size={12} /> : "Place Bet"}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* <ToastContainer /> */}
        {/* </td> */}
      </Fragment>
    );
  }
}

export default (PlaceMarketBet);
