import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserLayout } from 'App/Layout'
import { useSelector } from 'react-redux';

const PublicRoutes = ({ isAthenticated }) => {
    const { currentUser } = useSelector((state) => state.auth);
    if (currentUser && currentUser.reset_password && currentUser.reset_password == "1") {
        return <Navigate to={"/change-password"} />
    }
    else if (isAthenticated) {
        return <Navigate to={"/login-info"} />
    }

    return <UserLayout><Outlet /></UserLayout>
}
export default PublicRoutes