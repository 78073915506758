import React, { useEffect, useState, Fragment } from "react";
import { constant } from "App/helper/constant";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { beforeLogingetMatchDetailRequest, toggleAuthModalRequest } from "App/_redux/_actions";
import _ from "App/helper/_"
import { Utils } from "App/utils"
import { BetslipOpenBet, DeclaredMarkets, Timer } from "App/components"
import { commentaryType, differentFancyList } from "App/utils";
import { LoginModal } from "App/modals";
import MarketDetail from "./MarketDetail";
import SessionDetail from "./SessionDetail";
import WebSocketContextComponent from "App/context/webSocketContext";
import INFO_img from "../../assets/img/info.svg";
import SCOREBOARD from "../../assets/img/score_board.svg";

const BeforeLoginDetail = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let { event_id } = useParams();

    const { isLoader } = useSelector((state) => state.beforeLogin);
    const { GlobalAnnouncement } = useSelector((state) => state.match);


    const [state, setAllStateValue] = useState({
        betAmount: 0,
        redirect: "",
        width: window.innerWidth,
        height: window.innerHeight,
        bookmakerType: 0,
        bettingType: 0,
        price: 0,
        displayPrice: 0,
        marketId: "",
        selectionId: "",
        toggleBetForm: false,
        name: "",
        marketBetData: "",
        sessionBetData: "",
        eventId: "",
        shown: false,
        showAlertDialog: false,
        alertType: "",
        alertMessage: "",
        commentaryData: "",
        timer: 0,
        betPlacedClick: false,
        event_id: event_id,
        openBetSlipid: '',
    });
    const [matchDetail, setMatchDetails] = useState({});
    const [allActiveFancySessions, setAllActiveFancySessions] = useState([]);
    const [allMarketIds, setAllMarketIds] = useState([]);
    const [socket, setSocket] = useState(false);
    const [toggleRoom, setToggleRoom] = useState(false);
    const [oddsStack, setOddsStack] = useState([]);
    const [odd_diff, setOddDiff] = useState("");
    const [openSettingModal, setOpenSettingModal] = useState(false);
    const [openSessionModal, setOpenSessionModal] = useState(false);
    const [callBalanceAPI, setCallBalanceAPI] = useState(false);
    const [scorURL, setScorURL] = useState("score1");
    const [tvURL, setTvURL] = useState(false);
    const [open, setOpen] = useState(false)


    const [Events, setEvent] = useState([])



    const toggleAuthModal = (type) => {
        dispatch(toggleAuthModalRequest(type))
    }



    useEffect(() => {
        loadData();
        return () => {
            window.removeEventListener("resize", updateDimensions);
            if (state.socketValue) {
                state.socketValue.closeRoom(getAllMarketIds());
                setMatchDetails({});
            }
        };
    }, []);


    useEffect(() => {
        if (state.shown) {
            scrollToView();
        }
    });

    useEffect(() => {
        if (state.eventId && params.event_id !== state.eventId) {
            setState({ eventId: params.event_id });
            window.location.reload();
        }
    }, [params.event_id]);



    useEffect(() => {
        if (state.shown) {
            scrollToView();
        }
    });



    useEffect(() => {
        if (!state.toggleBetForm) {
            clearValues();
        }
    }, [state.toggleBetForm]);

    const setState = (obj, callback) => {
        setAllStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const clearValues = () => {
        setState({
            betAmount: 0,
            bookmakerType: 0,
            bettingType: 0,
            price: 0,
            displayPrice: 0,
            marketId: "",
            selectionId: "",
            name: "",
        });
    };

    const updateDimensions = () => {
        setState({ width: window.innerWidth, height: window.innerHeight });
    };

    const loadData = () => {
        window.addEventListener("resize", updateDimensions);
        fetchMatchDetailData();

        let shouldShowTV = sessionStorage.getItem("show_tv");
        if (shouldShowTV == 1) {
            setState({ shown: true });
        }
    };

    const fetchMatchDetailData = () => {
        const { event_id } = params;
        dispatch(
            beforeLogingetMatchDetailRequest({ event_id }, (res) => {
                matchDetailParse(res);
                setState({ eventId: event_id });
            })
        );
    };

    const matchDetailParse = (data) => {
        let finalData = [];
        finalData = data.market;
        data.market.map((market, markey) => {
            market.runners.map((run, key) => {
                market.runner_odds.find((odd) => {
                    if (run.selectionId === odd.selectionId) {
                        run["runner_odd"] = odd;
                        finalData[markey].runners[key] = run;
                    }
                });
            });
        });
        if (data?.event?.sport_id == 1) {
            let allMarket = finalData;
            for (let i = 0; i < allMarket.length; i++) {
                const marketRunner = allMarket[i].runners;
                const updatedRunner = [];
                let drawRunner = null;
                for (let j = 0; j < marketRunner.length; j++) {
                    if (marketRunner[j].runnerName == "The Draw") {
                        drawRunner = marketRunner[j];
                    } else {
                        updatedRunner.push(marketRunner[j]);
                    }
                }
                if (drawRunner) {
                    updatedRunner.push(drawRunner);
                }
                allMarket[i].runners = updatedRunner;
            }
            finalData = allMarket;
        }
        data.market = finalData;
        const liveData = data.event.score ? JSON.parse(data.event.score) : "";
        const parseData = parseLiveScore(liveData, data); //ig
        data.live_msg = parseData.liveScoreMsg;
        data.live_score1 = parseData.live_score1;
        data.live_score2 = parseData.live_score2;
        handleAllactiveFancy(state.allActiveFancySessions, data);
        setToggleRoom((toggle) => !toggle);
    };

    const getAllMarketIds = ({ market = [], fancy = [] }) => {
        const sessions = fancy;
        const ids = [];
        if (market?.length > 0) {
            for (let i = 0; i < market.length; i++) {
                ids.push(market[i].market_id);
            }
        }
        if (sessions?.length > 0) {
            for (let i = 0; i < sessions.length; i++) {
                ids.push("" + sessions[i].session_id);
            }
        }
        setAllMarketIds(ids);
    };

    const parseLiveScore = (liveData, isMatchEqualEvent) => {
        //
        let finalData = { live_score1: null, live_score2: null, liveScoreMsg: "" };

        if (liveData) {
            let liveScoreMsg = liveData?.data?.msg;
            let liveScore1 = `${liveData.data?.teams?.map((i) => i?.team_name)[0]} - ${liveData.data?.teams?.map((i) => i?.score)[0]} Ovs`;
            let liveScore2 = `${liveData.data?.teams?.map((i) => i?.team_name)[1]} - ${liveData.data?.teams?.map((i) => i?.score)[1]} Ovs`;
            let liveCrrScore = liveData?.data?.msg;

            let rr = "";
            if (!isMatchEqualEvent && liveData.data?.currentRunRate) {
                rr = " CRR: " + liveData.data?.currentRunRate;
                liveCrrScore = rr;
            }
            if (!isMatchEqualEvent && liveData.data?.requireRunRate) {
                liveScoreMsg = `${liveData.data?.teams?.map((i) => i?.team_name)[0]}  needed  ${liveData.data?.runNeeded}  runs from ${liveData.data?.ballsRemaining} balls`
                rr = `${liveScoreMsg}` + " RRR: " + liveData.data?.requireRunRate;
                liveCrrScore = rr;
            }


            let sixBall = liveData?.data?.last24balls;
            let players = liveData?.data?.currentPlayersScore;

            finalData = {
                live_score1: liveScore1,
                live_score2: liveScore2,
                liveScoreMsg: liveScoreMsg,
                sixBall: sixBall,
                players: players,
                liveCrrScore: liveCrrScore
            };
        }


        return finalData;
    };

    const updateSocketResponse = (data) => {
        const { event_id } = params;
        if (data && data.settlementDone) {
            updateSettleMent(data);
            return;
        }
        if (data && data.reloadPage) {
            setToggleRoom((toggle) => !toggle);
            return;
        }
        if (
            data &&
            data?.allActiveFancy &&
            data?.allActiveFancy?.event_id == event_id
        ) {
            handleAllactiveFancy(data.allActiveFancy.session_ids, matchDetail);
            return;
        }
        if (data?.addUpdateFancy?.event_id == event_id) {
            fetchMatchDetailData();
            return;
        }
        setMatchDetails((socketData) => {
            switch (true) {
                case !!data?.updateMarketOdds?.odds &&
                    data?.updateMarketOdds?.event_id == event_id:
                    const updatedData = data.updateMarketOdds;
                    try {
                        let finalData = [];
                        finalData = socketData.market;
                        socketData.market.map((market, markey) => {
                            if (updatedData.market_id == market.market_id) {
                                market.match_status = updatedData.status;
                                market.runner_odds = updatedData.odds;
                                market.runners.map((run, key) => {
                                    updatedData.odds.find((odd) => {
                                        if (run.selectionId == odd.selectionId) {
                                            run["runner_odd"] = odd;
                                            finalData[markey].runners[key] = run;
                                        }
                                    });
                                });
                            }
                        });
                        return { ...socketData, market: finalData };
                    } catch (error) {
                        return socketData;
                    }
                case !!data?.updateFancyOdds &&
                    data?.updateFancyOdds?.event_id == event_id:
                    const updatedFancy = data.updateFancyOdds;
                    let finalData = [...socketData.fancy];
                    socketData.fancy.map((fancyItem, fancyKey) => {
                        if (
                            fancyItem.session_id == updatedFancy.market_id &&
                            fancyItem.event_id == updatedFancy.event_id
                        ) {
                            fancyItem.lay_price1 = updatedFancy.lay_price1;
                            fancyItem.lay_size1 = updatedFancy.lay_size1;
                            fancyItem.back_price1 = updatedFancy.back_price1;
                            fancyItem.back_size1 = updatedFancy.back_size1;
                            fancyItem.game_status = updatedFancy.game_status;
                            finalData[fancyKey] = fancyItem;
                        }
                    });
                    return { ...socketData, fancy: finalData };
                case data?.updateScore?.event_id == socketData?.event?.event_id:
                    const liveData = data.updateScore.score
                        ? JSON.parse(data.updateScore.score)
                        : "";
                    const parseData = parseLiveScore(liveData, data?.updateScore?.match_id == data?.updateScore?.event_id);
                    return {
                        ...socketData,
                        live_msg: parseData.liveScoreMsg,
                        live_score1: parseData.live_score1,
                        live_score2: parseData.live_score2,
                        SixBall: parseData.sixBall,
                        players: parseData.players,
                        RunRate: parseData.liveCrrScore
                    };

                case data?.updateCommentary?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            button_label: data.updateCommentary.button_label,
                            commentary_button_id: data.updateCommentary.commentary_button_id,
                            btn_color: data.updateCommentary.btn_color,
                        },
                    };
                case data?.updateEventAnnouncement?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            announcement: data.updateEventAnnouncement.announcement,
                        },
                    };

                case data?.updateTVURL?.event_id == event_id:
                    return {
                        ...socketData,
                        event: {
                            ...socketData.event,
                            live_tv_url: data.updateTVURL.live_tv_url,
                        },
                    };
                case data?.updateFancyBetLock?.event_id == event_id:
                    let fancy = socketData.fancy;
                    for (let i = 0; i < fancy.length; i++) {
                        if (fancy[i].session_id == data.updateFancyBetLock.session_id) {
                            fancy[i].bet_lock = data.updateFancyBetLock.bet_lock;
                        }
                    }
                    return {
                        ...socketData,
                        fancy,
                    };
                case data?.updateFancySectionLock?.event_id == event_id:
                    let fancy1 = socketData.fancy;
                    for (let i = 0; i < fancy1.length; i++) {
                        if (
                            data.updateFancySectionLock.session_type.includes(
                                fancy1[i].session_type
                            )
                        ) {
                            fancy1[i].bet_lock = data.updateFancySectionLock.bet_lock;
                        }
                    }
                    return {
                        ...socketData,
                        fancy1,
                    };
                case data?.updateLockMarketBetting?.event_id == event_id:
                    let market = socketData.market;
                    for (let i = 0; i < market.length; i++) {
                        if (market[i].market_id == data.updateLockMarketBetting.market_id) {
                            let runner = market[i].runners;
                            for (let j = 0; j < runner.length; j++) {
                                if (
                                    data.updateLockMarketBetting.selection_id === "" ||
                                    runner[j].selectionId ==
                                    data.updateLockMarketBetting.selection_id
                                ) {
                                    runner[j].lock_team_betting =
                                        data.updateLockMarketBetting.lock_status;
                                }
                            }
                            market[i].runners = runner;
                        }
                    }
                    return {
                        ...socketData,
                        market,
                    };
                case data?.marketAnnouncement?.event_id == event_id:

                    // let fancy1 = socketData.fancy;
                    // for (let i = 0; i < fancy1.length; i++) {
                    //   if (
                    //     data.updateFancySectionLock.session_type.includes(
                    //       fancy1[i].session_type
                    //     )
                    //   ) {
                    //     fancy1[i].bet_lock = data.updateFancySectionLock.bet_lock;
                    //   }
                    // }
                    return {
                        ...socketData,
                        // fancy1,
                    };
                default:
                    return socketData;
            }
        });
    };


    const handleAllactiveFancy = (
        allActiveFancy = allActiveFancySessions,
        matchData
    ) => {
        let fancyData = matchData?.fancy.filter((itemFacny) =>
            allActiveFancy.length
                ? allActiveFancy.includes(JSON.stringify(itemFacny.session_id))
                : true
        );
        matchData.fancy = fancyData;
        setMatchDetails(matchData);
        getAllMarketIds(matchData);
        setAllActiveFancySessions(allActiveFancy);
    };

    const updateSettleMent = (data) => {
        setMatchDetails((socketData) => {
            if (socketData?.event?.event_id == data?.settlementDone.event_id) {
                if (data.settlementDone.bookmaker_type == 3) {
                    let allFancy = socketData.fancy;

                    let iIndex = -1;
                    for (let i = 0; i < allFancy.length; i++) {
                        if (allFancy[i].session_id == data.settlementDone.session_id) {
                            iIndex = i;
                            break;
                        }
                    }
                    if (iIndex > -1) {
                        allFancy.splice(iIndex, 1);
                        return { ...socketData, fancy: allFancy };
                    }
                }
                if (data.settlementDone.bookmaker_type == 2) {
                    let allMarket = socketData.market;
                    let iIndex = -1;
                    for (let i = 0; i < allMarket.length; i++) {
                        if (allMarket[i].market_id == data.settlementDone.market_id) {
                            iIndex = i;
                            break;
                        }
                    }
                    if (iIndex > -1) {
                        allMarket.splice(iIndex, 1);
                        return { ...socketData, market: allMarket };
                    }
                }
                return { ...socketData };
            } else {
                return { ...socketData };
            }
        });
    };

    const updateTimer = () => {
        if (state.timer == 10) {
            setState({ timer: 0, toggleBetForm: false });
        } else {
            setTimeout(() => {
                let time = state.timer;

                time += 1;
                setState({ timer: time }, () => {
                    if (!state.betPlacedClick) {
                        updateTimer();
                    }
                });
            }, 1000);
        }
    };

    const getMarketList = (type, allMarket = []) => {
        let filteredList = [];
        for (let i = 0; i < allMarket.length; i++) {
            if (type == 2) {
                if (
                    allMarket[i].market_type == 4 ||
                    allMarket[i].market_type == 6 ||
                    allMarket[i].market_type == 7
                ) {
                    filteredList.push(allMarket[i]);
                }
            } else if (type == 1) {
                if (
                    allMarket[i].market_type != 4 &&
                    allMarket[i].market_type != 6 &&
                    allMarket[i].market_type != 7
                ) {
                    filteredList.push(allMarket[i]);
                }
            }
        }
        if (filteredList?.length) {
            const tieMatchInd = filteredList?.findIndex(
                (item) => matchDetail?.event?.sport_id == 4 && item?.market_type == 4
            );
            if (tieMatchInd >= 0) {
                filteredList.push(...filteredList.splice(tieMatchInd, 1));
            }
        }
        return filteredList;
    };


    const scrollToView = () => {
        const ptDiv = document.getElementById("is_append_video");
        const myDiv = document.getElementById("append_video");
        const oddDiv = document.getElementById("content_matches_wrapper");
        window.addEventListener("scroll", function () {
            const rect = ptDiv.getBoundingClientRect();
            if (rect.top <= 50) {
                myDiv.classList.add("append_video");
                oddDiv.style.marginTop = myDiv.getBoundingClientRect().height + "px";
            } else {
                myDiv.classList.remove("append_video");
                oddDiv.style.removeProperty("margin-top");
            }
        });
    };

    const {
        betAmount,
        price,
        displayPrice,
        toggleBetForm,
        name,
        marketBetData,
        sessionBetData,
        refreshPrice,
        selectedData,
    } = state;


    return (
        <div className={`mainContainer_layOut `}  >
            {allMarketIds.length ? (
                <WebSocketContextComponent
                    marketList={allMarketIds}
                    handleSocketData={(e) => {
                        updateSocketResponse(e);
                    }}
                    closeAllRoom={toggleRoom}
                    setSocket={setSocket}
                    socket={socket}
                    event_id={event_id}
                />
            ) : (
                ""
            )}
            {/* Match list content */}
            <div onClick={() => { toggleAuthModal("LOGIN") }}>
                <div className="mainFlx_bx">
                    <div className="leftContent">
                        {/* <div className="flex anvMarquee  h-[28px] md:h-7 items-center md:m-[0px_0px_7px] md:rounded overflow-hidden">
                            <img className="h-4 px-1" src={INFO_img} />
                            <marquee
                                className="text-[12px] flex items-center font-semibold "
                                behavior=""
                                direction=""
                            >{GlobalAnnouncement != null ? GlobalAnnouncement.client_msg : `Welcome to Future9Club !!`}
                            </marquee>
                        </div> */}


                        {/* ICC Live */}

                        {/* Score board */}
                        {matchDetail && matchDetail?.event && matchDetail?.event.open_date && matchDetail?.event?.in_play == 0 &&
                            <div className="upcoming_title_outer">
                                <div className="upcoming_title">
                                    <div className="timer_div">
                                        <div className="flex justify-center items-center py-4 absolute top-[-78px]">
                                            <Timer EventDetails={matchDetail?.event} />
                                        </div>
                                    </div>
                                </div>
                                <div className="date_time_">
                                    {Utils.gameDate(matchDetail?.event?.open_date)} {" "}{Utils.getTime(matchDetail?.event?.open_date)}
                                </div>
                                <div className="vs_teams">
                                    {matchDetail.event.event_name}
                                </div>
                            </div>
                        }

                        {matchDetail && matchDetail?.event && matchDetail?.event?.in_play == 1 &&
                            <Fragment>
                                <div className="block">
                                    <div className="bg-active text-active-foreground flex justify-between font-semibold p-2 text-[12px] text-md items-center ">
                                        <div className="text-ellipsis w-full overflow-hidden text-nowrap ">{matchDetail?.event?.league_name} {">"} {matchDetail?.event?.event_name}</div>
                                        <div className="flex items-center text-nowrap">
                                            {/* <FaRegClock className="mr-1" />{Utils.getDateAndTime("MMM DD, YYYY", matchDetail?.event?.open_date)} {"|"} {Utils.getTime(matchDetail?.event?.open_date)} */}
                                            {(Utils.gameDate(matchDetail?.event?.open_date) === "Live" || matchDetail?.event?.in_play) ?
                                                <div className="">
                                                    <h4 className="liveTxt text-active-foreground">LIVE</h4>
                                                    <div class=" animated_line" >
                                                        <div class="line_animation" ></div>
                                                    </div>
                                                </div>

                                                : Utils.gameDate(matchDetail?.event?.open_date)}
                                            {Utils.gameDate(matchDetail?.event?.open_date) !== "Live" && <div className="">
                                                <span className="text-[10px] font-medium ml-1">{Utils.getTime(matchDetail?.event?.open_date)}</span>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="scoreBoard_main pb-0 border-none rounded-none mt-1">
                                    <div className="flex anvScor_bord p-[5px_11px] justify-between">

                                        <div className="flex max-w-[200px] w-full">
                                            <button className="scoreBtn-primary2 scorActive"><img className="relative -top-[1px]" src={SCOREBOARD} alt="" /> Score 1</button>
                                            <button className="scoreBtn-primary2"><img className="relative -top-[1px]" src={SCOREBOARD} alt="" /> Score 2</button>
                                        </div>

                                        <div className="flex items-center gap-2 lg:hidden">
                                            <p className="text-sm text-[#FFFFFF] font-medium">Live TV</p>

                                            <div>
                                                <input type="checkbox" className='liveTV_toggle'

                                                    id="liveTV-toggle"
                                                />
                                                <label htmlFor="liveTV-toggle" className='liveTV_lable'>
                                                    <span className="spanOn">On</span>
                                                    <span className="spanOff">Off</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-secondary text-secondary-foreground  flex flex-wrap gap-x-[22px] gap-y-[8px] w-full p-[0px_8px_11px]">
                                        {scorURL === "score1" ?
                                            <Fragment>
                                                {matchDetail?.event?.score_url ? <iframe className="w-full h-[10rem]" src={matchDetail?.event?.score_url} frameborder="0"></iframe> : <p className="w-full text-center text-color">No Score available</p>}
                                            </Fragment>

                                            : scorURL === "score2" ?
                                                <Fragment>
                                                    {matchDetail?.event?.score_widget ? <iframe className="w-full h-[10rem]" src={matchDetail?.event?.score_widget} frameborder="0"></iframe> : <p className="w-full text-center text-color">No Score available</p>}
                                                </Fragment>

                                                : null}

                                    </div>
                                </div>
                            </Fragment>
                        }
                        {/* Score board */}


                        {matchDetail?.event?.upline_hide_event === 1 ? (
                            <div className="row matchBox-Row">
                                <h3 className="in-play-row-left"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    Match hide, please contact to uplines.
                                </h3>
                            </div>
                        ) : (
                            <>
                                <MarketDetail
                                    event={matchDetail?.event}
                                    marketList={getMarketList(1, matchDetail?.market)}
                                    setBookmakerType={() => { }}
                                    setBettingType={() => { }}
                                    setPrice={() => { }}
                                    setDispalyPrice={() => { }}
                                    setMarketId={() => { }}
                                    setSelectionId={() => { }}
                                    setToggleBetForm={() => { }}
                                    setName={() => { }}
                                    setTeamName={() => { }}
                                    setBatType={() => { }}
                                    scrollToBottom={() => { }}
                                    // websocketValue={value}
                                    setSelectedData={() => { }}
                                    resetViewAfterYellowBtn={() => { }}
                                    reloadChildView={state.reloadChildView}
                                    // manageSocketValue={updateScoketValue}
                                    handleCommentaryStatusModal={() => { }}
                                    //   fetchMatchDetailData={fetchMatchDetailData}

                                    openBetSlipMarketId={() => { }}
                                    toggleBetForm={toggleBetForm}
                                    betPriceDetail={() => { }}
                                    betAmount={betAmount}
                                    setBetAmount={() => { }}
                                    placeBetRequest={() => { }}
                                    onDonePressed={() => { }}
                                    price={price}
                                    displayPrice={displayPrice}
                                    name={name}
                                    teamName={state.teamName}
                                    batType={state.batType}

                                    selectedData={selectedData}
                                    oddsStack={oddsStack}
                                    setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                />

                                <MarketDetail
                                    event={matchDetail?.event}
                                    marketList={getMarketList(2, matchDetail?.market)}
                                    setBookmakerType={() => { }}
                                    setBettingType={() => { }}
                                    setPrice={() => { }}
                                    setDispalyPrice={() => { }}
                                    setMarketId={() => { }}
                                    setSelectionId={() => { }}
                                    setToggleBetForm={() => { }}
                                    setName={() => { }}
                                    setTeamName={() => { }}
                                    setBatType={() => { }}
                                    scrollToBottom={() => { }}
                                    // websocketValue={value}
                                    setSelectedData={() => { }}
                                    resetViewAfterYellowBtn={() => { }}
                                    reloadChildView={state.reloadChildView}
                                    // manageSocketValue={updateScoketValue}
                                    handleCommentaryStatusModal={() => { }}
                                    //   fetchMatchDetailData={fetchMatchDetailData}

                                    openBetSlipMarketId={() => { }}
                                    toggleBetForm={toggleBetForm}
                                    betPriceDetail={() => { }}
                                    betAmount={betAmount}
                                    setBetAmount={() => { }}
                                    placeBetRequest={() => { }}
                                    onDonePressed={() => { }}
                                    price={price}
                                    displayPrice={displayPrice}
                                    name={name}
                                    teamName={state.teamName}
                                    batType={state.batType}

                                    selectedData={selectedData}
                                    oddsStack={oddsStack}
                                    setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                />


                                {matchDetail?.fancy?.length ? (
                                    differentFancyList(
                                        matchDetail?.fancy,
                                        sessionBetData ? sessionBetData : []
                                    ).map((fancy, index) => (
                                        <SessionDetail
                                            index={index}
                                            key={fancy?.session_type}
                                            matchDetailEvents={matchDetail?.event}
                                            sessionList={fancy}
                                            setBookmakerType={() => { }}
                                            setBettingType={() => { }}
                                            setPrice={() => { }}
                                            setDispalyPrice={() => { }}
                                            setMarketId={() => { }}
                                            setSelectionId={() => { }}
                                            setToggleBetForm={() => { }}
                                            setName={() => { }}
                                            setTeamName={() => { }}
                                            setBatType={() => { }}
                                            scrollToBottom={() => { }}
                                            // websocketValue={value}
                                            f_exposure={matchDetail?.f_exposure}
                                            handleCommentaryStatusModal={() => { }}
                                            setSelectedData={() => { }}
                                            showSessionBook={() => { }}
                                            //      fetchMatchDetailData={fetchMatchDetailData}
                                            openBetSlipid={state.openBetSlipid}
                                            openBetSlipMarketId={() => { }}
                                            EventDetails={matchDetail}

                                            toggleBetForm={toggleBetForm}
                                            betPriceDetail={() => { }}
                                            betAmount={betAmount}
                                            setBetAmount={() => { }}
                                            placeBetRequest={() => { }}
                                            onDonePressed={() => { }}
                                            price={price}
                                            displayPrice={displayPrice}
                                            name={name}
                                            teamName={state.teamName}
                                            batType={state.batType}
                                            event={() => { }}
                                            selectedData={selectedData}
                                            oddsStack={oddsStack}
                                            setOpenSettingModal={(e) => setOpenSettingModal(e)}
                                        />
                                    ))
                                ) : (
                                    <></>
                                )}

                            </>
                        )}



                    </div>


                    <div className="rightContent">
                        <div className="hidden lg:block">

                            <div className="mb-[6px] rounded-[3.45px] overflow-hidden">
                                <div className="bg-active text-active-foreground dark:bg-secondary dark:text-secondary-foreground min-h-[38px] flex justify-between items-center p-[5px_14px_5px_12px]">
                                    <p className="text-[13.72px] font-semibold ">Live TV</p>

                                    <div>
                                        <input type="checkbox" className='liveTV_toggle'

                                            id="liveTV_web-toggle"
                                        />
                                        <label htmlFor="liveTV_web-toggle" className='liveTV_lable'>
                                            <span className="spanOn">On</span>
                                            <span className="spanOff">Off</span>
                                        </label>
                                    </div>
                                </div>

                                {/* Add TV Here */}

                                {/* Add TV Here */}
                            </div>

                            <div className="matchOdd_tabBx mt-0">
                                <div className="flex anvScor_bord">
                                    <button className="scoreBtn-primary ">Match Odd</button>
                                    <button className="scoreBtn-primary">Bookmaker</button>
                                    <button className="scoreBtn-primary">Fancy</button>
                                    <button className="scoreBtn-primary">OTH. MKT.</button>
                                </div>
                            </div>

                            <div className="declareFancy">
                                <div className="declareFancy_innr anvScor_bord">
                                    <div>
                                        <h2 className="declPara">Declared Session</h2>
                                    </div>

                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                                            <path d="M1.48785 2.00005L7.01228 7.97756L12.4878 1.95517" className=" stroke-active-foreground dark:stroke-[#fff]" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </div>
    );
};

export default BeforeLoginDetail