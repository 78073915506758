import { casinoLogin, diamondCasinoLogin, getMasterData } from 'App/_redux/_services'
import { getStorage } from 'App/utils';
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

const CasinoDetail = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setStateValue] = useState({
        casinoList: [],
        casinoUrl: null
    })

    useEffect(() => {
        if (params.provider_code == "indian_casino") {
            // const item = location.state
            if (params && params.gameCode) {
                let gmCode = (params.gameCode).replace(/&&/g, '/')
                fetchDiamondCasino(gmCode)

            }
            return
        }
        if (params.provider_code && params.provider_code != "") {
            fetchCasino(params.provider_code, params.gameCode)
        }

    }, [])

    const setState = (obj, callback) => {
        setStateValue((s) => ({ ...s, ...obj }));
        if (callback) callback();
    };

    const fetchCasino = async (provider_code, gameCode) => {
        let param = { provider_code, gameCode }
        try {
            const res = await casinoLogin(param);
            if (res.data.launchURL) {
                setState({ casinoUrl: res.data.launchURL })
            }

        } catch (error) {
            if (error && error.error != null) {
                toast.error(error.error)
            }
            navigate(-1)
        }
    }

    const fetchDiamondCasino = async (game_code) => {
        let param = { game_code }
        try {
            const res = await diamondCasinoLogin(param);
            if (res.data.launchURL) {
                setState({ casinoUrl: res.data.launchURL })
            }

        } catch (error) {
            if (error && error.error != null) {
                toast.error(error.error)
            }
            navigate(-1)
        }
    }

    const { casinoUrl } = state
    return (

        <Fragment>
            <div className='w-full h-screen fixed top-0 left-0 z-[200] bg-white'>
                <div className='flex justify-between items-center bg-[#EAC83F]'>
                    {params.name && <div className='font-bold pl-2 h-[2rem] py-1 text-black'>{params.name}</div>}
                    <Link to="/casino" className='absolut right-2 top-2 z-[201] bg-red-600 px-[0.7rem] py-1 text-white cursor-pointer text-center font-bold' ><p>Exit</p></Link>
                </div>
                {casinoUrl && <iframe className='w-full h-[calc(100%_-_2rem)]' src={casinoUrl} frameborder="0" ></iframe>}
            </div>
        </Fragment>
    )
}

export default CasinoDetail